import React, { FC, useContext } from "react";
import { OneColumn } from "../Flexbox";
import { TinyNav } from "../TinyNav";

import gbtLogoUrl from "./gbt_logo.svg";
import oxbrytaLogoUrl from "./oxbryta_logo.svg";

import "./header.scss";
import { eventTracker } from "../../tracker";

type HeaderProps = {
  brand: "oxbryta";
};

export const Header: FC<HeaderProps> = ({ brand }) => {
  return (
    <header className="header">
      <div className="header__cee-brand">
        <span className="header__cee-brand__gbt">
          <a href="/">
            <img className="header__cee-brand__gbt__img" src={gbtLogoUrl} />
          </a>
        </span>
        <div className="header__cee-brand__headline">
          <span className="header__cee-brand__name">
            Clinical Education Exchange
          </span>
          <span className="header__cee-brand__tagline">
            Expert insights for healthcare professionals
          </span>
        </div>
      </div>
      <div className="header__cee-brand__tagline header__cee-brand__tagline--mobile">
        Expert insights for healthcare professionals
      </div>
      <div className="header__cee-brand--separator"></div>
      <div className={`header__brand header__brand--${brand}`}>
        <OneColumn>
          <TinyNav className="header-isi-links">
            <a
              data-analytics-event-action="Clicked"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="Top Navigation"
              data-analytics-event-label="Oxbryta Important Safety Information"
              href="/isi"
            >
              Important Safety Information
            </a>
            <span className="link__separator">|</span>
            <a
              data-analytics-event-action="Clicked"
              data-analytics-event-subject="Meeting Request"
              data-analytics-event-category="Top Navigation"
              data-analytics-event-label="Oxbryta Important Safety Information"
              onClick={() => {
                eventTracker({
                  eventCategory: "Prescribing Information",
                  eventAction: "Downloaded",
                  eventLabel: "Oxbryta Prescribing Information",
                });
              }}
              href="https://oxbryta.com/pdf/prescribing-information.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Prescribing Information
            </a>
          </TinyNav>
        </OneColumn>
        <div className={`header__brand__logo header__brand__logo--${brand}`}>
          <OneColumn>
            <a href="/">
              <img src={oxbrytaLogoUrl} />
            </a>
          </OneColumn>
        </div>
      </div>
    </header>
  );
};
