import React, { FC, PropsWithChildren } from "react";

import "./tiny-nav.scss";

type TinyNavProps = PropsWithChildren<{ className?: string }>;

export const TinyNav: FC<TinyNavProps> = ({ children, className }) => {
  const childrenArray = Array.isArray(children)
    ? children
    : children
    ? [children]
    : [];

  return (
    <nav className={`tiny-nav ${className}`}>
      <ul className="links">
        {childrenArray.map((link, index: number) => (
          <li key={index}>{link}</li>
        ))}
      </ul>
    </nav>
  );
};
