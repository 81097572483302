import React, { Component, FC } from "react";
import { OneColumn } from "../Flexbox";

import { eventTracker } from "../../tracker";

import "./styles.scss";

export class Isi extends Component {
  private isi?: Element;

  componentDidMount() {
    this.isi = document.createElement("div");
    document.body.appendChild(this.isi);
  }

  componentWillUnmount() {
    this.isi.parentNode.removeChild(this.isi);
    this.isi = null;
  }

  render() {
    return <IsiContent />;
  }
}

const IsiContent: FC<{}> = () => (
  <OneColumn>
    <div className="isi__container">
      <section className="isi">
        <div className="header-section">
          <div className="isi__section">
            <div className="isi__header">
              <strong>INDICATIONS AND USAGE</strong>
            </div>
            <p>
              Oxbryta is indicated for the treatment of sickle cell disease
              (SCD) in adults and pediatric patients 12 years of age and older.
            </p>
            <p>
              This indication is approved under accelerated approval based on
              increase in hemoglobin (Hb). Continued approval for this
              indication may be contingent upon verification and description of
              clinical benefit in confirmatory trial(s).
            </p>
          </div>

          <div className="isi__section">
            <div className="isi__header">IMPORTANT SAFETY INFORMATION</div>
            <h3>Contraindications</h3>
            <p>
              Oxbryta is contraindicated in patients with a history of serious
              drug hypersensitivity reaction to voxelotor or excipients.
            </p>
          </div>
        </div>

        <div className="isi__section">
          <h3>Warnings and precautions</h3>
          <div className="isi__subtitle">
            <strong>Hypersensitivity Reactions</strong>
          </div>
          <p>
            Serious hypersensitivity reactions after administration of Oxbryta
            have occurred in &lt;1% of patients treated. Clinical manifestations
            may include generalized rash, urticaria, mild shortness of breath,
            mild facial swelling, and eosinophilia.
          </p>
          <p>
            If hypersensitivity reactions occur, discontinue Oxbryta and
            administer appropriate medical therapy. Do not reinitiate Oxbryta in
            patients who experience these symptoms with previous use.
          </p>
        </div>
        <div className="isi__section">
          <div className="isi__subtitle">
            <strong>Laboratory Test Interference</strong>
          </div>
          <p>
            Oxbryta administration may interfere with measurement of Hb subtypes
            by (HbA, HbS, and HbF) HPLC. If precise quantitation of Hb species
            is required, chromatography should be performed when the patient is
            not receiving Oxbryta therapy.
          </p>
        </div>
        <div className="isi__section">
          <h3>Adverse reactions</h3>
          <div className="isi__subtitle">
            <strong>Clinical Trials Experience</strong>
          </div>
          <p>
            Serious adverse reactions occurred in 3% (3/88) of patients
            receiving Oxbryta 1,500 mg, which included headache, drug
            hypersensitivity, and pulmonary embolism occurring in 1 patient
            each.
          </p>
          <p>
            Adverse Reactions (≥10%) in patients receiving Oxbryta with a
            difference of &gt;3% compared to placebo: Headache (26% vs. 22%),
            Diarrhea (20% vs. 10%), Abdominal Pain (19% vs. 13%), Nausea (17%
            vs. 10%), Fatigue (14% vs. 10%), Rash (14% vs. 10%), and Pyrexia
            (12% vs. 7%).
          </p>
        </div>
        <div className="isi__section">
          <h3>Drug interactions</h3>
          <p className="isi__underline">Sensitive CYP3A4 Substrates</p>
          <p>
            Voxelotor increased the systemic exposure of midazolam (a sensitive
            CYP3A4 substrate). Avoid co-administration with sensitive CYP3A4
            substrates with a narrow therapeutic index. If unavoidable, consider
            dose reduction of the CYP3A4 substrate(s).
          </p>

          <p className="isi__underline">
            Strong CYP3A4 Inhibitors or Fluconazole
          </p>
          <p>
            Co-administration of strong CYP3A4 inhibitors or fluconazole may
            increase voxelotor plasma concentrations and may lead to increased
            toxicity. Avoid co-administration of strong CYP3A4 inhibitors or
            fluconazole. Decrease Oxbryta dosage if unavoidable.
          </p>

          <p className="isi__underline">Strong or Moderate CYP3A4 Inducers</p>
          <p>
            Co-administration of strong or moderate CYP3A4 inducers may decrease
            voxelotor plasma concentrations and may lead to reduced efficacy.
            Avoid co-administration of strong or moderate CYP3A4 inducers.
            Increase the Oxbryta dosage if unavoidable.
          </p>
        </div>
        <div className="isi__section">
          <h3>USE IN SPECIFIC POPULATIONS</h3>
          <div className="isi__subtitle">
            <strong>Lactation</strong>
          </div>
          <p>
            Because of the potential for serious adverse reactions in the
            breastfed child, including changes in the hematopoietic system,
            advise patients not to breastfeed while taking Oxbryta and for at
            least 2 weeks after the last dose.
          </p>

          <div className="isi__subtitle">
            <strong>Recommended Dosage for Hepatic Impairment</strong>
          </div>
          <p>
            Severe hepatic impairment increases voxelotor exposures. Reduce dose
            to 1,000 mg orally once daily for severe hepatic (Child Pugh C)
            impairment.{" "}
          </p>

          <p className="isi__pi-link">
            <strong>
              Please see{" "}
              <a
                data-analytics-event-action="Downloaded"
                data-analytics-event-subject="Prescribing Information"
                data-analytics-event-category="Prescribing Information"
                data-analytics-event-label="Oxbryta Prescribing Information"
                onClick={() => {
                  eventTracker({
                    eventAction: "Downloaded",
                    eventCategory: "Prescribing Information",
                    eventLabel: "Oxbryta Prescribing Information",
                  });
                  eventTracker({
                    eventAction: "Clicked",
                    eventCategory: "Bottom Navigation",
                    eventLabel: "About GBT",
                  });
                }}
                href="https://oxbryta.com/pdf/prescribing-information.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Full Prescribing Information
              </a>{" "}
              for Oxbryta for more information.
            </strong>
          </p>
        </div>
      </section>
    </div>
  </OneColumn>
);
