import React from "react";
import { OneColumn } from "../Flexbox";

import gbtLogo from "../Header/gbt_logo.svg";

import { TinyNav } from "../TinyNav";
import { eventTracker } from "../../tracker";

import "./footer.scss";

export type FooterProps = {
  prcNumber: string;
};

type FooterComponent = React.FC<FooterProps>;

export const Footer: FooterComponent = ({ prcNumber }) => (
  <footer className="footer">
    <OneColumn>
      <TinyNav className="footer__nav">
        <a
          onClick={() => {
            eventTracker({
              eventAction: "Followed",
              eventCategory: "External Sites",
              eventLabel: "https://www.gbt.com/about/",
            });
            eventTracker({
              eventAction: "Clicked",
              eventCategory: "Bottom Navigation",
              eventLabel: "About GBT",
            });
          }}
          href="https://www.gbt.com/about/"
          rel="noopener noreferrer"
          target="_blank"
        >
          About GBT
        </a>
        <span className="link__separator">|</span>
        <a
          onClick={() => {
            eventTracker({
              eventAction: "Followed",
              eventCategory: "External Sites",
              eventLabel: "https://www.gbt.com/terms-of-use/",
            });
            eventTracker({
              eventAction: "Clicked",
              eventCategory: "Bottom Navigation",
              eventLabel: "Terms of Use",
            });
          }}
          href="https://www.gbt.com/terms-of-use/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        <span className="link__separator">|</span>
        <a
          onClick={() => {
            eventTracker({
              eventAction: "Followed",
              eventCategory: "External Sites",
              eventLabel: "https://www.gbt.com/privacy-policy/",
            });
            eventTracker({
              eventAction: "Clicked",
              eventCategory: "Bottom Navigation",
              eventLabel: "Privacy Policy",
            });
          }}
          href="https://www.gbt.com/privacy-policy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </TinyNav>
      <img className="footer__logo" src={gbtLogo} />
      <div className="footer__legalese">
        <p>
          <br className="copyright-break" />
          &copy; Global Blood Therapeutics, Inc
        </p>
        <br />
        <p>
          Oxbryta is a registered trademark of Global Blood Therapeutics, Inc.
        </p>
        <p>
          All other trademarks referenced herein are the property of their
          individual owners.
        </p>
        <p>{prcNumber}</p>
        <br />
        <p>
          <strong>
            This site is intended for US healthcare professionals only.
          </strong>
        </p>
      </div>
    </OneColumn>
  </footer>
);
