import React, { Children, FC, PropsWithChildren } from "react";

import "./styles.scss";

type ContainerProps = PropsWithChildren<{ className?: string }>;

export const OneColumn: FC<ContainerProps> = ({ children, className }) => (
  <div className={"flex flex--one" + " " + className}>{children}</div>
);

export const TwoColumn: FC<ContainerProps> = ({ children, className }) => {
  const [firstChild, ...rest] = Array.isArray(children) ? children : [];
  return (
    <div className={"flex flex--two" + " " + className}>
      <div className="flex__cell">{firstChild}</div>
      <div className="flex__cell">{rest}</div>
    </div>
  );
};

export const FourColumn: FC<ContainerProps> = ({ children }) => {
  return (
    <div className="flex flex--four">
      {children &&
        Children.map(children, (child) => {
          <div className="flex__cell">{child}</div>;
        })}
    </div>
  );
};
